/* Table of contents
––––––––––––––––––––––––––––––––––––––––––––––––––

Note: Do not write any CSS in this file. Only use it to import other CSS files.
Note: '~' in the import statement means the file is imported from the node_modules directory. Bootstrap functions is an example.

- Bootstrap Functions.
- Bootstrap Mixins.
- Framework SASS Variables - override Bootstrap's SASS Vars in this file.
- Bootstrap Variables.
- Framework Mixins
- Framework Utilities
- Bootstrap Core
- Framework CSS Custom Properties (CSS Variables)
- Framework Base
- Framework Components
- Framework Vendors
- Custom CSS - Add your custom CSS to this file
*/


/* Bootstrap Functions - DO NOT CHANGE ANYTHING IN HERE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import '~bootstrap/scss/functions';

/* Framework SASS Variables - GO HERE TO OVERRIDE BOOTSTRAP VARS.
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import "abstracts/variables";

/* Bootstrap SASS Vars - DO NOT CHANGE ANYTHING IN HERE
__________________________________________________*/
@import '~bootstrap/scss/variables';

/* Bootstrap Mixins - DO NOT CHANGE ANYTHING IN HERE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import '~bootstrap/scss/mixins';

/* Framework Mixins
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import 'abstracts/mixins';

/* Bootstrap SASS Maps - DO NOT CHANGE ANYTHING IN HERE
__________________________________________________*/
@import '~bootstrap/scss/maps';


/* Framework Utilities - Needs to be imported before main bootstrap file.
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import '~bootstrap/scss/utilities';

/* Framework Base
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import 'base/base';

/* Framework Components
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import 'components/components';

// Utilities
@import 'utilities/utilities';
@import "~bootstrap/scss/utilities/api";


/* Framework Vendor Overrides
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import 'vendors/vendors';

/* Template Custom CSS - Add your own CSS to this file
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import 'custom';