@import "~bootstrap/scss/accordion";

//
// accordion.scss
// Extended from Bootstrap
//

// Accordion

.accordion-button:after {
  background-image: none;
  font-family: var(--theme-font-icon);
  font-style: var(--theme-font-icon-style);
  content: "\EA13";
}

.accordion-button:not(.collapsed):after {
  background-image: none;
  content: "\F1AE";
}