//
// avatar.scss
// Theme component
//

// General

.avatar {
  width: var(--theme-avatar-size);
  height: var(--theme-avatar-size);
  display: block;
}