/* Reveal Animation. */
[data-aos="reveal-right"], [data-aos="reveal-left"], [data-aos="reveal-up"], [data-aos="reveal-down"] {
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        transition-property: transform;
        transition-timing-function: var(--theme-slideout-transition-in);
        transition-duration: .5s;
        transition-delay: inherit;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $body-bg;
    }
}

[data-aos="reveal-right"] {
    &.aos-animate  {
        &:after {
            transform: translateX(100%);
        }
    }
}

[data-aos="reveal-left"] {
    &.aos-animate  {
        &:after {
            transform: translateX(-100%);
        }
    }
}

[data-aos="reveal-up"] {
    &.aos-animate  {
        &:after {
            transform: translateY(-100%);
        }
    }
}

[data-aos="reveal-down"] {
    &.aos-animate  {
        &:after {
            transform: translateY(100%);
        }
    }
}