@import "~bootstrap/scss/root";

:root {

  /* Icon font family */
  --theme-font-icon: 'remixicon';
  --theme-font-icon-style: 'normal';

  @each $breakpoint, $value in $grid-breakpoints {
    --theme-breakpoint-#{$breakpoint}: #{$value};
  }
}