@keyframes fadeIn {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes fadeInUp {
	0% {
		display: none;
		opacity: 0;
		transform: translateY(10px);
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
		transform: translateY(0);
	}
}

.animation-float {
	animation: float 3s ease-in-out infinite;
}

/* loop from 1 - 10 for different floating option */
@for $i from 1 through 10 {
	.animation-float-#{$i} {
		animation: float #{$i}s ease-in-out infinite;
	}
}

/* loop from 1 - 20 for different marquee timing option */
@for $i from 1 through 20 {
	.animation-marquee-#{$i}0 {
		animation: marquee #{$i}0s linear infinite;
	}
}

@keyframes float {
	0% {
		transform: translate3d(0, 0, 0);
	}

	50% {
		transform: translate3d(0, 7px, 0);
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes marquee {
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		transform: translate3d(-100%, 0, 0);
	}
}