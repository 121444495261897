body {
    overflow-x: hidden;
}

.disable-child-pointer * {
    pointer-events: none;
}

/* Custom font face */
@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}