.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 1;

  &.text-primary {
    background-image: $gradient-primary;
  }

  &.text-dark {
    background-image: $gradient-dark;
  }

  &.text-warning {
    background-image: $gradient-warning;
  }

  &.text-success {
    background-image: $gradient-success;
  }

  &.text-info {
    background-image: $gradient-info;
  }

}