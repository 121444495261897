
.app-cover {
  z-index: 5;
}

.case-study-feature-list {
  @extend .list-unstyled;


  .feature-list-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    &:first-child {
      margin-top: 3rem;
    }

  }

  .feature-list-item-content {

  }

  .icon {
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      margin-right: 1rem;
      margin-bottom: 0;
    }

  }

}

.highlight-01 {
  padding-top: 8rem;
  padding-bottom: 8rem;

  @include media-breakpoint-up(md) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
}