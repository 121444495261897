@import "~bootstrap/scss/buttons";

.btn-gradient {

  &.btn-primary {
    background-image: $gradient-primary;
  }

  &.btn-dark {
    background-image: $gradient-dark;
  }

  &.btn-info {
    background-image: $gradient-info;
  }

  &.btn-warning {
    background-image: $gradient-warning;
  }

  &.btn-success {
    background-image: $gradient-success;
  }

  &.btn-alert {
    background-image: $gradient-alert;
  }

}